import AccordionInfosCard from 'components/AccordionInfosCard'
import Button from 'components/Button'
import { navigate } from 'gatsby'
import { useCards } from 'hooks/useCards'
import { useLoader } from 'hooks/useLoader'
import AppLayout from 'layouts/AppLayout'
import { cardsFormId } from 'utils/make/constants'
import makeTrackings from 'utils/make/trackings'
import storage from 'utils/Storage'

import './CardResume.scss'

const CardResume = () => {
  const { selectedCard, selectedCardInfo, address } = useCards()
  const { setLoader } = useLoader()

  const userInfo = storage.session.getItem('user_data')

  if (!selectedCardInfo) return null

  return (
    <AppLayout
      contentClassName="card-resume"
      layoutClassName="card-resume-layout"
      contentHeaderInfos={{
        onTrack: () => {
          makeTrackings.formBacked({
            formId: cardsFormId,
            formName: 'cards',
            stepId: '7760b402-1a96-475b-b5c8-2c0389591925',
            stepName: 'cards-resume',
            stepNumber: 4,
          })
        },
      }}
    >
      <div className="card-resume__header">
        <img
          src={selectedCardInfo.image}
          alt={selectedCardInfo.title}
          className="header-card__image"
        />

        <h1 className="header-card__title">{selectedCardInfo.title}</h1>

        <ul className="header-card__details">
          {selectedCardInfo.details.map((item, index) => (
            <li key={index} className="header-card__detail">
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="card-resume__content">
        <AccordionInfosCard
          title="Dados do titular"
          infos={[
            { label: 'CPF', value: userInfo.document },
            { label: 'Nome', value: userInfo.name },
            { label: 'E-mail', value: userInfo.email },
            { label: 'Celular', value: userInfo.phone },
          ]}
          onTrack={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '72a74ec7-77a4-45f1-b526-42dc953c73a0',
              location: 'edit-section',
              name: 'click-form-cards-resume-personal-data',
            })
          }}
          isEditable
          onEdit={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '0287d179-b408-437f-8a42-b6c89b58175b',
              location: 'edit-section',
              name: 'click-form-cards-resume-personal-data-edit',
              text: 'editar',
            })

            navigate('/registro', {
              state: {
                product: 'cards',
                isEditing: true,
                onBackFromEdit: '/cartoes/resumo',
              },
            })
          }}
        />

        <AccordionInfosCard
          title="Endereço de entrega"
          infos={[
            { label: 'CEP', value: address.zipCode },
            { label: 'Rua', value: address.street },
            { label: 'Número', value: address.number },
            { label: 'Complemento', value: address.complement },
            { label: 'Bairro', value: address.neighborhood },
            { label: 'Cidade', value: address.city },
            { label: 'UF', value: address.state },
          ]}
          onTrack={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '2e2240bf-f82a-430b-9fb9-8d9c21f73fe6',
              location: 'edit-section',
              name: 'click-form-cards-resume-address',
            })
          }}
          isEditable
          onEdit={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '4c8e2930-66be-4823-9687-1b46b1176eb6',
              location: 'edit-section',
              name: 'click-form-cards-resume-address-edit',
              text: 'editar',
            })

            navigate('/cartoes/endereco')
          }}
        />
      </div>

      <Button
        className="card-resume__button"
        onClick={() => {
          setLoader({
            isLoading: true,
            text: 'Estamos analisando sua proposta...',
          })

          setTimeout(() => {
            makeTrackings.formContinued({
              formId: cardsFormId,
              formName: 'cards',
              stepId: '097e5ec9-b9f8-464f-8563-89f16ed93da1',
              stepName: 'cards-resume',
              stepNumber: 4,
            })
            makeTrackings.formSubmitted({
              formId: cardsFormId,
              formName: 'cards',
              stepId: '15ea6d87-39d3-4448-af63-1dabef426525',
              stepName: 'cards-resume',
              stepNumber: 4,
            })

            makeTrackings.card({ selected: selectedCard, address })

            navigate('/cartoes/conclusao')
            setTimeout(() => {
              setLoader({ isLoading: false })
            }, 1000)
          }, 2000)
        }}
      >
        Solicitar cartão
      </Button>
    </AppLayout>
  )
}

export default CardResume
